import { APIs_URL } from "../constants";
import axios from "axios";
import updateToken from "@/middlewares/update-token";
import store from "../store/index";

axios.defaults.baseURL =
  process.env.NODE_ENV === "production"
    ? APIs_URL.PRODUCTION
    : APIs_URL.STAGING;

axios.interceptors.request.use(async function (config) {
  let token = null;
  if (store.getters["auth/accessToken"]) {
    token = await updateToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // alert(Error(error.response.data.message || "something wrong happened"));
    throw new Error(
      (error.response && error.response.data && error.response.data.message) ||
      "something wrong happened"
    );
    // return Promise.reject(error);
  }
);
