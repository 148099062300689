export default {
	contactData(state) {
		return state.contacts.result;
	},
	careersData(state) {
		return state.careers.result;
	},
	totalNumberOfElementsContacts(state) {
		return state.contacts.totalNumberOfElements;
	},
	totalNumberOfElementsCareers(state) {
		return state.careers.totalNumberOfElements;
	},
	totalNumberOfPagesContacts(state) {
		return state.contacts.totalNumberOfPages;
	},
	totalNumberOfPagesCareers(state) {
		return state.careers.totalNumberOfPages;
	},

	website(state) {
		// console.log("from getter", state.website)
		return state.website;
	},

	websiteColumnsNames(state) {
		return state.columnsNames;
	}


};
