import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
	rtl: false,
	theme: {
		themes: {
			light: {
				primary: "#7e7e7e",
				$accent: "#b38930",
				success: "#288a35",
				error: "#c41b1b",
				gray: "#8c8c8c",
				black: "#000",
				white: "#000",
				published: "#288a35",
				unpublished: "#b38930",
				ended: "#c41b1b",
			},
		},
	},
	icons: {
		iconfont: "mdi", // default - only for display purposes
	},
});
