import Vue from "vue";
import Keycloak from "keycloak-js";
import { KEYKLOACK_URL } from "../constants";

// Rename realm and clientId fields
let options = {
	url: process.env.NODE_ENV === "production" ? KEYKLOACK_URL.PRODUCTION : KEYKLOACK_URL.STAGING,
	realm: "contactform",
	clientId: "contactform-frontend",
	checkLoginIframe: false
};

const _keycloak = Keycloak(options);

const Plugin = {
	install(Vue) {
		Vue.$keycloak = _keycloak;
	}
};

Plugin.install = (Vue) => {
	Vue.$keycloak = _keycloak;
	Object.defineProperties(Vue.prototype, {
		$keycloak: {
			get() {
				return _keycloak;
			}
		}
	});
};

Vue.use(Plugin);

export default Plugin;
