import "./styles/style.scss";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from './plugins/moment'

import updateToken from "@/middlewares/update-token";
import { i18n } from "@/plugins/lang";
import "@/plugins/keycloak";
import "@/plugins/axios";
import "./plugins/moment";
import getLocale from "./mixins/get-locale";

Vue.mixin(getLocale);
Vue.use(require('vue-moment'))

Vue.config.productionTip = false;

Vue.$keycloak
	.init({ onLoad: "login-required" })
	.then((auth) => {
		if (!auth) {
			localStorage.removeItem("loggedIn");
			window.location.reload();
		} else {
			new Vue({
				router,
				store,
				vuetify,

				i18n,
				render: (h) => h(App, { props: { keycloak: Vue.$keycloak } }),
			}).$mount("#app");

			if (
				Vue.$keycloak.token &&
				Vue.$keycloak.idToken &&
				Vue.$keycloak.token != "" &&
				Vue.$keycloak.idToken != ""
			) {
				localStorage.setItem("loggedIn", true);
				let payload = {
					username: Vue.$keycloak.subject,
					roles: Vue.$keycloak.tokenParsed.realm_access.roles,
					idToken: Vue.$keycloak.idToken,
					accessToken: Vue.$keycloak.token,
				};
				store.dispatch("auth/login", payload);
			}
		}

		window.onfocus = () => {
			updateToken();
		};
	})
	.catch(() => {
		Vue.$log.error("Authenticated Failed");
	});
