<template>
  <v-app>
    <app-drawer> </app-drawer>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <error-overlay />
    <success-overlay />
  </v-app>
</template>

<script>
import AppDrawer from "./components/layout/drawer.vue";
import ErrorOverlay from "./components/error-overlay.vue";
import SuccessOverlay from "./components/success-overlay.vue";

export default {
  name: "App",
  components: { AppDrawer, ErrorOverlay, SuccessOverlay },
};
</script>
<style lang="scss"></style>
