import vuetifyAr from "vuetify/lib/locale/ar";

const ar = {
	$vuetify: {
		...vuetifyAr
	},
	form: {
		is_required: "مطلوب",
		must_be_valid: "يجب أن تكون صالحة",
	},
	datatable: {
		search: "ابحث...",
		loading: "جاري التحميل انتظر من فضلك",
		actions: "الأدوات",
	},
	buttons: {
		save: "تحديث",
		cancel: "الغاء",
		choose_file: "رفع ملف"
	},
	actions: {
		logout: "تسجيل خروج",
		export: "تحميل",
		done: "تم"
	},
	bool: {
		yes: "نعم",
		no: "لا"
	},
	locales: {
		en: "الإنجليزية",
		ar: "العربية"
	}
};

export default ar;
