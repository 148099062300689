<template>
	<transition name="popup">
		<div
			v-if="successDialog"
			class="position-fixed success-wrapper p-4 bg-success"
		>
			<div
				class="image-container position-absolute cursor-pointer"
				@click="close"
			>
				<img
					src="@/assets/images/close-white.svg"
					class="img-fluid "
					width="30"
          alt="close"
				/>
			</div>
			<h2 class="text-center mt-4 mb-2">تم بنجاح :)</h2>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters("dialog", ["successDialog"]),
	},
	methods: {
		close() {
			this.$store.dispatch("dialog/hideSuccessDialog");
		},
	},
};
</script>

<style lang="scss" scoped>
.success-wrapper {
	border: 1px solid $color-input-placeholder;
	border-radius: 20px;
	top: 10px;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 10;
	width: 300px;
	max-width: 90%;
	margin: auto;
}

h2 {
	color: $color-white;
}

.image-container {
	top: 10px;
	right: 10px;
}
.popup-enter-active,
.popup-leave-active {
	transition: transform 0.5s ease;
}

.popup-enter,
.popup-leave-to {
	transform: translate(-50%, -150px);
}
</style>
