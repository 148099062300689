<template>
  <v-navigation-drawer app :right="$i18n.locale === 'ar'">
    <v-layout fill-height column>
      <v-list flat class="flex-grow-1 pt-0 menu-items">
        <!-- <img id="img-logo" :src="`${this.website.logoUrl}`" /> -->
        <span class="website-name">{{ this.website.name }}</span>
        <!-- <hr /> -->
        <v-list-item-group class="item-group" v-model="selectedItem" mandatory>
          <template v-for="(item, index) in menuItems">
            <v-list-item :key="index">
              <router-link
                class="anchor"
                :class="menuItemColor()"
                :to="{ name: item.routeName }"
              >
                <v-icon v-text="item.icon"></v-icon>
                <!-- <v-list-item-icon>
                  <cases-svg
                    class="menu-icon"
                    :fill="menuIconColor(index)"
                    v-if="item.icon === 'cases'"
                  />
                  <home-svg
                    class="menu-icon"
                    :fill="menuIconColor(index)"
                    v-if="!item.icon"
                  />
                  <dashboard-svg
                    class="menu-icon"
                    :fill="menuIconColor(index)"
                    v-if="item.icon === 'dashboard'"
                  />
                </v-list-item-icon> -->

                <v-list-item-content>
                  <v-list-item-title class="menu-text">
                    {{ item[`title_${$i18n.locale}`] }}
                  </v-list-item-title>
                </v-list-item-content>
                <!-- <v-list-item-avatar
                  v-if="item.avatar"
                  class="me-4"
                  :color="isCGCAdmin ? 'gold' : 'primary'"
                  width="28"
                  height="28"
                  min-width="28"
                  min-height="28"
                >
                  <span class="white--text font-weight-bold cases-counter"
                    >48</span
                  >
                </v-list-item-avatar> -->
              </router-link>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
      <div class="mx-5 mb-4 d-flex flex-column">
        <v-divider class="mb-4 divider" style="opacity: 0.16"></v-divider>
        <!-- :class="isCGCAdmin ? 'white--text' : 'primary--text'" -->
        <v-list-item link @click="logout">
          <v-list-item-title>{{ $t("actions.logout") }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

// let menuItems = [
//   {
//     title_en: "Submissions",
//     title_ar: "التواصل",
//     path: "/contact-us",
//     icon: "mdi-star",
//     routeName: "ContactUs",
//   },
// ];

export default {
  name: "AppDrawer",
  components: {},
  data() {
    return {
      selectedItem: 0,
      languages: this.$i18n.availableLocales,
      // drawerItems: menuItems,
    };
  },
  computed: {
    ...mapGetters("contactUs", ["website"]),
    pageTitle: function () {
      return this.$route.meta.title;
    },
    menuItems() {
      return this.website?.websiteRoutingList?.map((item) => ({
        title_en:
          item.routingName.name.charAt(0).toUpperCase() +
          item.routingName.name.slice(1),
        path: `/${item.routingName.name}`,
        icon: "mdi-star",
        routeName: item.routingName.name,
      }));
    },
  },
  methods: {
    async loadBank() {},

    async loadWebsite() {
      await this.$store.dispatch("contactUs/getWebsite");
    },

    menuItemColor() {
      return true;
    },
    menuIconColor(index) {
      return this.selectedItem === index ? "#E8AE1E" : "#fff";
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
  created() {
    // this.loadBank();
  },
  async mounted() {
    await this.loadWebsite();
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = "admin portal";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-text {
  padding-top: 1rem;
  padding-left: 1rem;
  margin-top: 1rem;
  color: #3730c073;
  font-size: 2rem;
}
.menu-items {
  .v-list-item {
    a {
      display: flex;
      width: 100%;
    }

    .v-list-item__icon {
      &:first-child {
        margin-left: 0 !important;
        margin-right: 0 !important;
        align-self: center;
      }

      .menu-icon {
        width: 1.25rem;
        max-width: 1.25rem;
        margin-inline-end: 0.8125rem;
      }
    }

    .menu-text {
      font-size: 1rem;
      margin-left: 1rem;
      font-weight: $font-medium;
      letter-spacing: 0.4px;
    }

    .cases-counter {
      font-size: 0.875rem;
    }
  }
}
::v-deep #btn-lang {
  min-width: 6.2rem;
  max-width: fit-content;
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.875rem;
  background-color: rgba($color: #fff, $alpha: 0.1) !important;
  border: 1px solid rgba($color: #fff, $alpha: 0.1) !important;

  &.bank {
    border-color: rgba($color: #282828, $alpha: 0.1) !important;
  }

  .v-btn__content {
    font-size: 0.8125rem;
    font-weight: $font-medium;
    letter-spacing: 0.33px;
  }
}

.v-application--is-ltr .v-btn__content .v-icon--left {
  margin-left: 0;
}
.item-group {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  // padding-left: 1rem;
}
.website-name {
  font-size: 2rem;

  align-items: center;
  color: #424242cc;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
}
</style>
