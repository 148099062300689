export default {
	async showErrorDialog({ commit }, payload) {
		commit("showErrorDialog", payload);
	},
	async hideErrorDialog({ commit }) {
		commit("hideErrorDialog");
	},

	async showSuccessDialog({ commit }) {
		commit("showSuccessDialog");
	},
	async hideSuccessDialog({ commit }) {
		commit("hideSuccessDialog");
	},

	async showHideSuccessDialog({ dispatch }) {
		await dispatch("showSuccessDialog");
		setTimeout(() => dispatch("hideSuccessDialog"), 2000);
	},
};
