import NetworkManager from "@/network/network-manager.js";
export default {
	async loadContactUs(context, payload) {
		const response = await NetworkManager.post(
			`/contact-us/find-all-paginated`,
			payload
		);
		context.commit("setContactUs", response);
	},
	async downloadContactUsExcel() {
		return await NetworkManager.download(`/contact-us/export`);
	},
	async downloadContactUsCsv() {
		return await NetworkManager.download(`/contact-us/export-csv`);
	},
	async filterContactUs(context, filterObject) {
		const response = await NetworkManager.post(`/contact-us/find-all-paginated-and-filtered`, filterObject);

		context.commit("setContactUs", response);
	},
	async filterWebsite(context, filterObject) {
		const response = await NetworkManager.post("/contact-us/find-all-paginated-and-filtered-website", filterObject);
		context.commit("setContactUs", response);
	},
	async deletedContactUsItem(context, deleteContctItem) {
		await NetworkManager.put(
			`/contact-us/delete-item`,
			deleteContctItem
		);
		context.commit("deletedContactUsItem", deleteContctItem);
	},


	async getWebsite(context) {
		const response = await NetworkManager.get(`/website/getWebsite`);
		// const website_id = response.id;
		context.commit("getWebsite", response);
	},

	async getWebsiteColumns(context, payload) {
		// console.log("websiteId", payload.websiteId);

		const response = await NetworkManager.post(`/column-name/${payload.websiteId}`);
		context.commit("getWebsiteColumnsNames", response)
	},
	async loadCareers(context, payload) {
		const response = await NetworkManager.post(
			`/career/find-all-paginated`,
			payload
		);
		context.commit("setCareers", response);
	},

}