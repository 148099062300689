export default {
	showErrorDialog(state, value) {
		state.showDialog = true;
		state.page = value.page;
		state.message = value.error || "";
		state.errorCode = "n";
		if (
			value.error &&
			value.error.response &&
			value.error.response.status &&
			value.error.response.status >= 500
		) {
			state.errorCode = "b";
		}
	},
	hideErrorDialog(state) {
		state.showDialog = false;
		state.page = "";
		state.errorCode = "";
		state.message = "";
	},
	showSuccessDialog(state) {
		state.successDialog = true;
	},
	hideSuccessDialog(state) {
		state.successDialog = false;
	},
};
