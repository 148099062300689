<template>
	<transition name="fade">
		<div
			class="dialog-wrapper cursor-pointer"
			@click="closeDialog"
			v-show="showDialog"
		>
			<div class="dialog-error" @click.stop>
				<img
					@click="closeDialog"
					src="@/assets/images/error.svg"
					:alt="$t('images_alt.error')"
				/>
				<h2>{{ $t("error_msg") }}</h2>
				<h3 v-if="page != ''">{{ page }}-{{ errorCode }}</h3>
				<h4 v-if="message != ''">{{ message }}</h4>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "ErrorOverlay",
	props: {},
	computed: {
		...mapGetters("dialog", ["showDialog", "page", "errorCode", "message"]),
	},
	methods: {
		closeDialog() {
			this.$store.dispatch("dialog/hideErrorDialog");
		},
	},
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.dialog-wrapper {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba($color: #000, $alpha: 0.5);
	z-index: 5;

	.dialog-error {
		width: 700px;
		position: absolute;
		left: 0;
		right: 0;
		top: 200px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 3.4375rem;
		background-color: #ebebeb;

		h2 {
			font-size: 1.75rem;
			color: $color-black;
			font-weight: $font-semibold;
			letter-spacing: 0.78px;
			margin: 1.5rem 0;
		}

		h3 {
			font-weight: $font-medium;
		}
	}
}
</style>
