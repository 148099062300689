import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store/index";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		component: () => import("../views/contact-us.vue"),
	},
	{
		path: "/contact-us",
		name: "submissions",
		component: () => import("../views/contact-us.vue"),
		// meta: { requiresAdmin: true }
	},
	{
		path: "/careers",
		name: "careers",
		component: () => import("../views/careers.vue"),
		// meta: { requiresAdmin: true }
	},

];


// const router = new VueRouter({
// 	// Prefix for all routes
// 	mode: "history",
// 	routes,
// });

// const router = new VueRouter({
// 	base: "/admin",
// 	routes
// });
const router = new VueRouter({
	routes
  });
  
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAdmin)) {
		// this route requires admin
		document.title = "admin portal";
		// Check if logged in and set user store object
		if (localStorage.getItem("loggedIn")) {
			if (
				Vue.$keycloak.token &&
				Vue.$keycloak.idToken &&
				Vue.$keycloak.token != "" &&
				Vue.$keycloak.idToken != ""
			) {
				// let payload = {
				// 	username: Vue.$keycloak.subject,
				// 	roles: Vue.$keycloak.tokenParsed.realm_access.roles,
				// 	idToken: Vue.$keycloak.idToken,
				// 	accessToken: Vue.$keycloak.token,
				// };
				// store.dispatch("auth/login", payload);
			}
		}
		next();
	} else {
		next(); // make sure to always call next()!
	}
});

export default router;
