export default {
	setContactUs(state, payload) {
		state.contacts = payload;

	},
	setCareers(state, payload) {
		state.careers = payload;

	},
	exportContactUs(state, payload) {
		state.export = payload
	},

	deletedContactUsItem(state, payload) {
		const contactUsItem = state.contacts.result.find(
			({ id }) => id === payload.id
		);
		const index = state.contacts.result.indexOf(contactUsItem);
		state.contacts.result.splice(index, 1);
	},


	getWebsite(state, payload) {
		state.website = payload;
		// console.log("from mutation", state.website);
	},
	getWebsiteColumnsNames(state, payload) {
		state.columnsNames = payload;
	}

};
