import vuetifyEn from "vuetify/lib/locale/en";

const en = {
	$vuetify: {
		...vuetifyEn,
	},
	form: {
		is_required: "is required",
		must_be_valid: "must be valid",
		choose: "choose",
	},
	datatable: {
		search: "Search...",
		loading: "Loading... Please wait",
		actions: "Actions",
	},
	buttons: {
		save: "Save",
		cancel: "Cancel",
		choose_file: "Choose File",
	},
	actions: {
		logout: "Logout",
		export: "Export",
		done: "Done",
	},
	bool: {
		yes: "Yes",
		no: "No",
	},
	locales: {
		en: "English",
		ar: "Arabic",
	},
	images_alt: {
		error: "error",
	},
	error_msg: "something wrong",


	contact_us: {
		contact_us: "Submissions",
		mobile_number: "Mobile Number",
		id: "ID",
		email: "Email",
		country: "Country",
		job_title: "Job Title",
		project: "Project",
		name: "Name",
		website: "Website Name",
		additional_information: "Additional Information",
		creation_date: "Creation Date"
	}
};

export default en;
